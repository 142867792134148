import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [
  {
    title: "",
    subsubCategories: [
      {
        title: "Campana de flujo laminar",
        subsubsubCategories: [],
      },
      {
        title: "Autoclaves vapor/peróxido",
        subsubsubCategories: [],
      },
      {
        title: "Esterilizadores de vapor",
        subsubsubCategories: [],
      },
      {
        title: "Laboratorio portátil de análisis clínicos",
        subsubsubCategories: [],
      },
    ],
  },
];

const Laboratorio = () => {
  return (
    <Layout>
      <Category data={data} title="Laboratorio" />
    </Layout>
  );
};

export default Laboratorio;
